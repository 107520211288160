import { StaticImage } from "gatsby-plugin-image";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import ServiceBase from "../../components/service";

const DocsMaintenance = () => (
  <ServiceBase
    title={"Japanese Docs Site Build &<br/> Maintenance Service"}
    bgClass="bg-[url('../images/services/docs-website-building-and-maintenance/banner.png')]"
  >
    <Fragment>
      <Helmet>
        <title>{"Japanese Docs Site Build & Maintenance Service | README Digital"}</title>
      </Helmet>
      <div className="grid md:grid-cols-2 gap-x-8 mb-[100px]">
        <StaticImage
          src="../../images/services/docs-website-building-and-maintenance/feature-1.jpg"
          alt="Documentation website creation All in Japanese, on a budget"
          layout="fullWidth"
          className="mb-8"
        />
        <div>
          <h2 className="text-[36px] font-bold first-letter:text-[#F13D6F] leading-[60px] mb-[30px]">
            Docs website creation - all in Japanese, on a budget
          </h2>
          <p className="font-bold text-[18px]">
            Establishing a subsidiary in Tokyo is very costly. Be wise enough to avoid such critical
            budgettings and start from creating your support documents in the local language. Unlike
            the common localization services, we compose the basic tutorial docs of your product by
            the hand of our in-house engineers, building them up as a comprehensive documentation
            website for you. README Digital is a "real" docs service of the engineers, by the
            engineers, for the engineers, while enabling you to save the initial expansion
            spendings.
          </p>
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-x-8 mb-[100px]">
        <StaticImage
          src="../../images/services/docs-website-building-and-maintenance/feature-2.jpg"
          className="order-first md:order-last mb-4"
          alt="Specializing in entry level with lots of UI screenshots that people love most"
          layout="fullWidth"
        />
        <div>
          <h2 className="text-[36px] font-bold first-letter:text-[#F13D6F] leading-[60px] mb-[30px]">
            Specializing in entry level with lots of UI screenshots
          </h2>
          <p className="font-bold text-[18px] mb-6">
            We focus on the introductory level tutorials that local users/support engineers need
            most. Our engineers will actually use your product and show the tips to the readers,
            making our publications more “real,” unlike simply translating your original docs. Also,
            we use a lot of screenshots to configure the operation flow as an axis, helping the
            readers understand visually. Each doc will be reviewed, edited, and published by our
            local experts.
          </p>
          {/* <a
            href="https://docs.readmedigital.com/l/en"
            target="_blank"
            rel="noreferrer"
            className="text-[#F13D6F] text-[20px]"
          >
            Check out our sample docs site
          </a> */}
          <a
            target="_blank"
            rel="noreferrer"
            className="p-2 px-4 text-white inline-flex items-center justify-center bg-gradient-to-r from-[#F13D6F] to-[#FF7C01] rounded-[20px]"
          >
            Check out our sample docs site
          </a>
        </div>
      </div>
    </Fragment>
  </ServiceBase>
);

export default DocsMaintenance;
